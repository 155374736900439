import React, { FC, Dispatch, SetStateAction } from "react";
import {
  Grid,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
import SelectorField from "../../components/selector-field";
import { ISupplyTypeRO } from "../../gateways/supply-type.interfaces";
import FormTextField from "../../components/form-text-field";
import { IInvoice, IInvoiceType } from "../../gateways/invoices.interfaces";
// import SearchIcon from '@material-ui/icons/Search';
import { IProviders } from "../../gateways/providers.interface";
import DragAndDrop from "../../components/drag-drop";
// import DropFile from "../../components/drag-drop";
import { InvoiceGateway } from "../../gateways/invoices-gateway";
import { S3Gateway } from "../../gateways/s3.gateway";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { FormatInvoiceAmountToFront, formattedDate } from "../../utils/utils";

interface IManualInvoiceProps {
  item: any;
  supplyinfo: string[];
  invoiceInfo: IInvoice[];
  handleInvoiceHashResult: (invoice: IInvoice[]) => void;
  onChange: (name: string, value: any) => void;
  supplyTypes: ISupplyTypeRO[];
  invoiceTypes: IInvoiceType[];
  handlePdfUriData: (data: string) => void;
  pdfUriData: string;
  isPeriodical: boolean;
  onChangeCheckbox: () => void;
  providers: IProviders[];
  handleInvoiceExists: (invoiceNumber: string) => void;
  handleCupsCancelled: (cups: string) => void;
  handleValidateDate: (name: string, value: any) => void;
  handleValidateAmount: (amount: string) => void;
}

const useStyles = makeStyles((theme) => ({
  selectorFields: {
    marginBottom: "1%",
  },
  writtingZone: {
    marginTop: "-1.5em",
  },
  dropZone: {
    marginTop: "0.3%",
    marginBottom: "0.3%",
  },
  dateZone: {
    marginTop: "-3em",
  },
  checkBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5em",
  },
  container: {
    height: "120%",
    padding: "1%",
  },
  textFields: {
    marginBottom: "0.5em",
  },
  dateField: {
    marginTop: "1em",
  },
  icon: {
    marginRight: "1%",
    display: "flex",
    alignItems: "center",
    color: "#B1C904",
    // paddingRight: "5%"
  },
}));

const ManualInvoiceForm: FC<IManualInvoiceProps> = (props) => {
  const classes = useStyles();
  const invoiceGateway = useIoC(InvoiceGateway);
  const s3Gateway = useIoC(S3Gateway);

  const snackbar = useSnackbar();

  const handleChange = async (file: any) => {
    props.onChange("invoice_pdf", file.name);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      (async () => {
        try {
          const uriData = reader.result as string;

          //get hash from file using API
          const hash = await s3Gateway.getHashFromUriData(uriData);

          if (hash.length === 0)
            snackbar.enqueueSnackbar(`El archivo no se puede leer.`, {
              variant: "error",
            });

          const hash_result: IInvoice[] = await invoiceGateway.findInvoicesByQuery({
            hash,
          });

          if (
            hash_result != null &&
            Array.isArray(hash_result) &&
            hash_result.length > 0
          ) {
            snackbar.enqueueSnackbar(`El archivo ya existe en el sistema!`, {
              variant: "warning",
            });

            props.handleInvoiceHashResult(hash_result);

            window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });

            return;
          }

          props.handlePdfUriData(uriData);
        } catch (e) {
          console.error("handleChange => ", e);
          snackbar.enqueueSnackbar(`PDF Error: ${e}`, {
            variant: "error",
          });
        }
      })();
    };
  };

  const handleDateValidation = (event: any) => {
    const { name, value } = event.target;
    props.handleValidateDate(name, value);
  };

  const handleOnBlurInvoiceNumber = (event: any) => {
    const { value } = event.target;
    props.handleInvoiceExists(value);
  };

  const handleOnBlurCups = (event: any) => {
    const { value } = event.target;
    props.handleCupsCancelled(value);
  };

  const handleAmountValidation = (event: any) => {
    const { value } = event.target;
    props.handleValidateAmount(value);
  };

  const handleInvoiceInfoDelete = () => {
    props.handleInvoiceHashResult([])
  }

  return (
    <Grid container spacing={4} className={classes.container}>
      {/* <Grid item xs={12}>
            </Grid> */}

      <Grid item xs={6} className={classes.writtingZone}>
        <SelectorField
          name="supply_type_id"
          fullWidth
          inputLabel="Tipo de suministro"
          onChange={props.onChange}
          required
          value={props.item.supply_type_id}
          values={props.supplyTypes.map((item) => {
            const styledName = {
              electricity: "Electricidad",
              fuel: "Combustible",
              water: "Agua",
            };
            return { id: item.id, value: (styledName as any)[item.name] };
          })}
          emptyValue={true}
          className={classes.selectorFields}
        />
        <SelectorField
          name="provider"
          fullWidth
          inputLabel="Comercializadora"
          onChange={props.onChange}
          required
          value={props.item.provider}
          values={props.providers.map((item) => {
            return { id: item.id, value: item.alias };
          })}
          emptyValue={true}
          className={classes.selectorFields}
          disabled={!!!props.item.supply_type_id ? true : false}
          valueNotId={true}
        />
        <SelectorField
          name="invoice_type_id"
          fullWidth
          inputLabel="Tipo de factura"
          onChange={props.onChange}
          required
          value={props.item.invoice_type_id}
          values={props.invoiceTypes.map((item) => {
            const styledName = {
              corrective: "Rectificativo",
              consum: "Consumo",
              meter: "Contador",
              serviceFee: "Cuota de Servicio",
            };
            return { id: item.id, value: (styledName as any)[item.name] };
          })}
          emptyValue={true}
        />

        <FormTextField
          fullWidth
          name="supply_reference"
          label={"Cups/Referencia"}
          required
          type="text"
          value={props.item.supply_reference}
          onChange={props.onChange}
          className={classes.textFields}
          onBlur={handleOnBlurCups}
        />

        <FormTextField
          fullWidth
          name="invoice_amount"
          label={"Importe total (€)"}
          required
          type="text"
          value={props.item.invoice_amount}
          onChange={props.onChange}
          onBlur={handleAmountValidation}
          placeholder={"Ej: 25.643,89"}
        />
        <FormTextField
          fullWidth
          name="invoice_number"
          label={"Núm. Factura"}
          required
          type="text"
          value={props.item.invoice_number}
          onChange={props.onChange}
          className={classes.textFields}
          onBlur={handleOnBlurInvoiceNumber}
        />

        <Grid container item xs={12} spacing={3} className={classes.dropZone}>
          <Grid item xs={8} className={classes.dateZone}>
            <FormTextField
              fullWidth
              name="issue_date"
              label={"Fecha emisión"}
              placeholder={"DD/MM/YYYY"}
              required
              type="text"
              value={props.item.issue_date}
              onChange={props.onChange}
              className={classes.dateField}
              onBlur={handleDateValidation}
            />
          </Grid>

          <Grid item xs={4} className={classes.dateZone}>
            <FormControlLabel
              control={
                <Checkbox
                  color={"primary"}
                  checked={props.isPeriodical}
                  onChange={props.onChangeCheckbox}
                />
              }
              label={"Factura periódica"}
              className={classes.checkBox}
              disabled
            />
          </Grid>

          <Grid item xs={6} className={classes.dateZone}>
            {props.isPeriodical === true && (
              <FormTextField
                fullWidth
                name="start_period"
                label={"Fecha inicio"}
                placeholder={"DD/MM/YYYY"}
                required
                type="text"
                value={props.item.start_period}
                onChange={props.onChange}
                className={classes.dateField}
                onBlur={handleDateValidation}
              />
            )}
          </Grid>
          <Grid item xs={6} className={classes.dateZone}>
            {props.isPeriodical === true && (
              <FormTextField
                fullWidth
                name="end_period"
                label={"Fecha fin"}
                placeholder={"DD/MM/YYYY"}
                required
                type="text"
                value={props.item.end_period}
                onChange={props.onChange}
                className={classes.dateField}
                onBlur={handleDateValidation}
              />
            )}
          </Grid>
        </Grid>

        <Box>
          <Typography variant="overline">
            Información del Suministro:
          </Typography>
          {props.supplyinfo.map((value, index) => {
            return (
              <Box key={index}>
                <Typography variant="caption" display="block" gutterBottom>
                  🔘{value}
                </Typography>
              </Box>
            );
          })}
        </Box>

        {props.invoiceInfo.length > 0 ? (
          <Box>
            <Box display="flex">
              
              <div className={classes.icon}>
                <WarningIcon fontSize="small" />
              </div>
                <Typography variant="overline">Información de la factura registrada:</Typography>
            </Box>

            {props.invoiceInfo.map((invoice: IInvoice) => (
              <Box key={invoice.id}>
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>Número de factura:</strong> {invoice.invoice_number}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>Cups:</strong> {invoice.cups}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>Importe:</strong> {FormatInvoiceAmountToFront(invoice.amount ?? null)}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>Fecha de emisión:</strong> {formattedDate(invoice.release_date ?? null)}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>Fecha de entrada:</strong> {formattedDate(invoice.input_date ?? null)}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>Periodo:</strong> {formattedDate(invoice.start_date ?? null)} al {formattedDate(invoice.end_date ?? null)}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>Tipo de suministro:</strong> {invoice.supply_type}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  <strong>Introducido por:</strong> {invoice.ebm_username ? invoice.ebm_username : "Bot"}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <></>
        )}
      </Grid>

      <Grid item xs={6} className={classes.dropZone}>
        {/* <DropFile fileName={props.item.invoice_pdf} handleDrop={handleChange} /> */}
        <DragAndDrop 
          fileName={props.item.invoice_pdf} 
          handleDrop={handleChange}
          handleInvoiceInfoDelete={handleInvoiceInfoDelete}
        />
      </Grid>
    </Grid>
  );
};

export default ManualInvoiceForm;
