import moment from "moment";

const validateEmail = (email: string) => {
    return email.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) ? true : false;
  };

export const emailValidationError = (email:string | null | undefined) => {
  let result = undefined;

  if (typeof email !== 'undefined' && email != null && email.length > 0)
  {
    let emails = email.includes(';') ? email.split(';').filter(e => e.replaceAll(' ', '')) : [email.replaceAll(' ', '')]
    
    if (emails.length > 0)
    {
      emails.forEach(em => {
        if (!validateEmail(em))
        {
          result = true;
        }
      });
      if (typeof result === 'undefined')
        result = false;

    } else 
      result = true;

  }

  return result;
}

export const obtainDomainFromEmail = (email: string | null | undefined) => {

  if (!emailValidationError(email) && email != null)
  {
    //is valid email or email list
    let emails = email.includes(';') ? email.split(';').filter(e => e.replaceAll(' ', '')) : [email.replaceAll(' ', '')]

    if (emails.length > 0)
    {
      const em = emails[0].split('@');
      return em[1];
    }

  } else
    return undefined;

}

export const formattedDate = (date: string | Date | null) => {
  return moment(date).format("DD/MM/YYYY");
};

export const FormatInvoiceAmountToFront = (amount: string | null) => {

  if (!amount || amount.length === 0)
    return amount;

  let final_amount = amount.replace("$", "") + "€";

  if (final_amount.includes(',') && final_amount.includes('.'))
  {
    const indexOfComa = final_amount.indexOf(',');
    const indexOfPoint = final_amount.indexOf('.');

    if (indexOfPoint < indexOfComa)
      return final_amount; // 1.000,01
    else {
      //1,000.01
      const temp = final_amount.replace(',', 'T');
      final_amount = temp.replace('.', ',').replace('T', '.');
    }
    
  } else if (final_amount.includes(',') && !final_amount.includes('.')){
      //1,000
      final_amount = final_amount.replace(',', '.');
  } else if (!final_amount.includes(',') && final_amount.includes('.')){
      //100.01
      final_amount = final_amount.replace('.', ',');
  }

  return final_amount;
}