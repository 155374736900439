export interface IInvoiceType {
    id: number;
    name: string;
}

export interface IAddManualInvoice {
    supply_type_id: number | null;
    invoice_type_id: number | null;
    invoice_number: string;
    invoice_amount: string;
    provider: string | null;
    supply_reference: string;
    issue_date: string;
    is_periodical: boolean;
    start_period?: string;
    end_period?: string;
    invoice_pdf: string;
}


export interface IInvoice {
    id: number;
    cups?: string;
    invoice_number?: string;
    input_date: Date;
    release_date?: Date;
    start_date?: Date;
    end_date?: Date;
    amount: string;
    supply_type?: string;
    link?: string;
    hash?: string;
    platform?: string;
    electric_supply_id?: number;
    fuel_supply_id?: number;
    water_supply_id?: number;
    invoice_type_id?: number;
    ebm_username?: string;
    editor_username?: string;
    edition_date?: string;
    empty_cups?: string;
    supply_not_configured_date?: Date;
    onedrive_not_configured_date?: Date;
    invoice_blacklist_date?: Date;
    invoice_without_date?: Date;
    bot_capture_validation_date?: Date;
    onedrive_no_folder_for_cups_date?: Date;
    onedrive_no_folder_date?: Date;
    onedrive_saved_date?: Date;
    operate_no_mail_date?: Date;
    operate_sended_date?: Date;
    client_no_mail_date?: Date;
    client_sended_date?: Date;
    process_ended_date?: Date;
    process_ended_no_cups_date?: Date;
    process_ended_black_list_cups_date?: Date;
    process_ended_discard_cups_date?: Date;
    process_ended_unregistered_cups_date?: Date;
    process_ended_no_validating_existed_cups_date?: Date;
    process_ended_no_correct_invoice_date?: Date;
    process_ended_black_list_supply_date?: Date;
    process_ended_is_duplicated?: Date;
    validation_opname?: string;
    validation_opname_date?: Date;
    validation_urgency: number;
    end_validation_client_ok?: Date;
    end_validation_client_issue?: Date;
    end_validation_client_verificated?: Date;
    duplicated_is_validated?: Date;
    duplicated_original_id?: number;
    electric_supply?: any | null;
    fuel_supply?: any | null;
    water_supply?: any | null;
    invoice_type?: any | null;
  }

export const initManualInvoice: IAddManualInvoice = {
    supply_type_id: null,
    invoice_type_id: null,
    invoice_number: "",
    invoice_amount: "",
    provider: null,
    supply_reference: "",
    issue_date: "",
    is_periodical: false,
    start_period: "",
    end_period: "",
    invoice_pdf: "",
}