import React, { FC, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import { useSnackbar } from "notistack";

interface IDrop {
  handleDrop: (file: any) => void;
  fileName: string;
  handleInvoiceInfoDelete: () => void;
}

const DragAndDrop: FC<IDrop> = (props) => {
  const useStyles = makeStyles((theme) => ({
    rootContainer: {
      display: "flex",
      justifyContent: "center",
      height: "80%",
      border: `dashed 2px ${!!props.fileName ? "#B1C904" : "#a0a0a0"}`,
      color: "#5b5b5b",
      fontWeight: "bold",
      borderRadius: 1,

      position: "relative",
    },
    innerRootContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      fontSize: 20,
    },
    innerRootContainerFile: {
      width: "100%",
      display: "flex",
      // flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 20,
    },
    detailFile: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // paddingRight: "5%",
      // padding: "10px",
    },
    icon: {
      marginRight: "3%",
      display: "flex",
      alignItems: "center",
      color: "#B1C904",
      paddingLeft: "5%"
    },
    inputPdfFileLabel: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inputPdfFile: {
      // visibility: "hidden",
      width: "100%",
      height: "100%",
      position: "absolute",
      
      opacity: 0,
    },
    button: {
      decoration: "none",
      // marginRight: "3%",
      // display: "flex",
      // alignItems: "center",
      color: "#B1C904",
    },
    text: {
      marginLeft: "2%",
    },

  }));

  const [rootFile, setRootFile] = useState('')

  const classes = useStyles();
  const snackbar = useSnackbar();

  // const handleOnDrop = (e: any) => {
  //   e.preventDefault();
  //   const file = e.dataTransfer.files[0];
  //   if (file.type !== "application/pdf") {
  //     snackbar.enqueueSnackbar("Sólo pueden subirse archivos PDF", {
  //       variant: "error",
  //     });
  //     return;
  //   }
  //   props.handleDrop(file);
  // };

  const handleDeleteFile = (e: any) => { 
    e.preventDefault();
    props.handleDrop("")
    props.handleInvoiceInfoDelete()
   }

  const readFile = ( e:any ) => {

    const file = e.target.files[0];

    if (file.type !== "application/pdf") {
      snackbar.enqueueSnackbar("Sólo pueden subirse archivos PDF", {
        variant: "error",
      });
      return;
    }
    props.handleDrop(file);
    const rootFile = URL.createObjectURL(e.target.files[0])
    setRootFile(rootFile)
  }

  return (
    <div
      className={classes.rootContainer}
      onDragOver={(e) => e.preventDefault()}
    >
      {!!!props.fileName ? (
        <div className={classes.innerRootContainer}>
          <label className={classes.inputPdfFileLabel}>
                <div className={classes.icon}>
                  <CloudUploadIcon fontSize="large" />
                </div>
                <div>
                  ARRASTRA TUS ARCHIVOS...
                </div>
          </label>
          <input 
            className={classes.inputPdfFile}
            type="file"
            multiple={ false }
            onChange={ readFile }
            />
        </div>
      ) : (
        <div className={classes.innerRootContainerFile}>
          <iframe src={`${rootFile}#toolbar=0`} title="application/pdf" style={{width:"100%", height: "100%"}} />
          <div 
            className={classes.detailFile}
            >
              <div className={classes.text}>
                {props.fileName}
              </div>
              <Button 
                className={classes.button}
                onClick={handleDeleteFile}
                >
                  <HighlightOffIcon  fontSize="large" />
                  {/* <p className={classes.text}>Eliminar</p> */}
              </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DragAndDrop;