import React, { FC, useState, useEffect } from "react";
import { useNavigator } from "../../contexts/navigator.context";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useTranslation } from "react-i18next";
import ManualInvoiceForm from "./manual-invoice-form";
import useIoC from "../../contexts/ioc.context";
import { SupplyTypeGateway } from "../../gateways/supply-type.gateway";
import { ISupplyTypeRO } from "../../gateways/supply-type.interfaces";
import {
  IAddManualInvoice,
  IInvoice,
  IInvoiceType,
  initManualInvoice,
} from "../../gateways/invoices.interfaces";
import { InvoiceGateway } from "../../gateways/invoices-gateway";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, Typography, makeStyles } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useSnackbar } from "notistack";
import { IProviders } from "../../gateways/providers.interface";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import { useUser } from "../../contexts/user.context";
import { MongoGateway } from "../../gateways/mongo.gateway";
import { SupplyConnectionBotGateway } from "../../gateways/supply-connection-bot.gateway";

import Dialog from '@material-ui/core/Dialog';
import { ClientTypesGateway } from "../../gateways/clients-types.gateway";
import { IClientTypes } from "../../gateways/clients-types.interfaces";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  button: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 1,
  },
  inline: {
    display: 'flex'
  },
  dialog: {
    padding: '10px',
    maring: '40px',
  },
  textDialogInfo:{
    paddingLeft: '30px',
  },
  textDialog:{
    fontSize: '18px'
  }

}));

const AddManualInvoice: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const snackbar = useSnackbar();

  const [, navigatorDispatch] = useNavigator();
  const [{ user }] = useUser();

  const [item, setItem] = useState<IAddManualInvoice>(initManualInvoice);
  const [supplyTypes, setSupplyType] = useState<ISupplyTypeRO[]>([]);
  const [invoiceTypes, setInvoiceTypes] = useState<IInvoiceType[]>([]);
  const [providers, setProviders] = useState<IProviders[]>([]);
  const [pdfUriData, setPdfUriData] = useState<string>("");
  const [isPeriodical, setIsPeriodical] = useState<boolean>(true);
  const [supplyInfo, setSupplyInfo] = useState<string[]>(["Vacío"]);
  const [clientTypes, setClientTypes] = useState<IClientTypes[]>([]);
  const [waitResponse, setWaitResponse] = useState<boolean>(false);
  const [invoiceInfo, setInvoiceInfo] = useState<IInvoice[]>([]);
  
  const supplyTypeGateway = useIoC(SupplyTypeGateway);
  const clientTypesGateway = useIoC(ClientTypesGateway);
  const invoiceGateway = useIoC(InvoiceGateway);
  const providersGateway = useIoC(ProvidersGateway);
  const mongoGateway = useIoC(MongoGateway);
  const supplyConnectionBotGateway = useIoC(SupplyConnectionBotGateway);

  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = () => {
    if (
      !!!item.supply_type_id ||
      !!!item.invoice_type_id ||
      !!!item.invoice_number ||
      !!!item.invoice_amount ||
      !!!item.supply_reference ||
      !!!item.issue_date ||
      !!!item.invoice_pdf ||
      !!!item.provider ||
      (isPeriodical && (!!!item.start_period || !!!item.end_period))
    ) {
      snackbar.enqueueSnackbar(`Todos los campos son obligatorios`, {
        variant: "error",
      });
      // setOpenDialog(true);
      return;
    } else {
      setOpenDialog(true);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };


  useEffect(() => {
    navigatorDispatch({
      type: "set-header",
      header: {
        title: t("manualInvoices.title"),
        icon: InsertDriveFileIcon,
      },
    });
  }, []);

  useEffect(() => {
    (async () => {
      const supplyTypesData = await supplyTypeGateway.findAll();
      setSupplyType(supplyTypesData);
      const invoiceTypesData = await invoiceGateway.findAllTypes();
      setInvoiceTypes(invoiceTypesData);
      const clientTypesData = await clientTypesGateway.findAll();
      setClientTypes(clientTypesData);
    })();
  }, []);

  const handleChange = async (name: string, value: any) => {
    if (name === "supply_type_id") {
      const providersData = await providersGateway.findWithFilters({
        providerTypeId: "1",
        supplyTypeId: `${value}`,
      });
      setProviders(providersData);
    }
    const updateItem = {
      ...item,
      [name]: typeof value === "string" ? value.trim() : value,
    };
    setItem(updateItem);
  };

  const handlePdfUriData = async (data: string) => {
    setPdfUriData(data);
  };

  const formatDate = (value: string) => {
    let [day, month, year] = value.split('/');
    if(+month > 12 || +year < 2000) return null;
    return new Date(+year, +month - 1, +day);
  }

  const resetDate = (name: string) => {
    let updatedItem;
    switch(name) {
      case "issue_date":
        updatedItem = { ...item, ...{ issue_date: "" } };
        break;
      case "start_period":
        updatedItem = { ...item, ...{ start_period: "" } };
        break;
      case "end_period":
        updatedItem = { ...item, ...{ end_period: "" } };
        break;
      default:
        updatedItem = item;
        break;
    }
    setItem(updatedItem);
  }

  const handleDateAlertError = (name: string, error: string) => {
    resetDate(name);
    snackbar.enqueueSnackbar(error, { variant: "error" });
    return;
  }

  const handleValidateDate = (name: string, value: any) => {
    const validation = formatDate(value);

    const valueDate = moment(value, 'DD/MM/YYYY', true);

    if (valueDate > moment().add(2, 'month')){
      handleDateAlertError(
        name, 
        "La fecha no puede ser mayor a 2 meses desde la fecha actual"
      );
    } else if (valueDate <= moment('2000-01-01')){
      handleDateAlertError(
        name, 
        "La fecha no puede ser anterior a 2000"
      );
    }

    if(!!validation && validation instanceof Date 
      && validation.toString() !== "Invalid Date" && value.length === 10
      && valueDate.isValid()
    ) {
      if(name === "start_period" && !!item.end_period) {
        const endPeriod = formatDate(item.end_period);
        if(endPeriod instanceof Date 
          && endPeriod.toString() !== "Invalid Date" 
          && validation > endPeriod) {
          handleDateAlertError(
            name, 
            "La fecha de inicio no puede ser posterior a la fecha fin"
          );
        }
      } else if(name === "end_period" && !!item.start_period) {
        const startPeriod = formatDate(item.start_period);
        if(startPeriod instanceof Date
          && startPeriod.toString() !== "Invalid Date"  
          && validation < startPeriod) {
          handleDateAlertError(
            name, 
            "La fecha fin no puede ser anterior a la fecha de incio"
          );
        }
      }
    } else {
      handleDateAlertError(
        name, 
        "Fecha no válida. Introduce una siguiendo el formato DD/MM/YYYY"
      );
    }
  }

  const handleValidateAmount = (amount: string) => {
    const regex = /^-?\d{1,3}(\.\d{3})*(,\d{1,2})?$/;
    const validAmount = regex.exec(amount);
    if(!!!validAmount) {
      setItem((prevItem: IAddManualInvoice) => { return {...prevItem, ...{ invoice_amount: "" }} });
      snackbar.enqueueSnackbar(
        `Introduce un importe válido. Ej: 1.083,56 / 567,10 / -58.324,65 / 10 / 10,1 / -10,1 / 10,10 / -10,10`,
        { variant: "error" }
      );
    } 
  }

  const handleIsPeriodical = async () => {
    if (isPeriodical) {
      const newItem = { ...item, ...{ start_period: "", end_period: "" } };
      setItem({ ...newItem, ...{ end_period: "" } });
    }
    setIsPeriodical(!isPeriodical);
  };

  const handleInvoiceHashResult = async (invoice: IInvoice[]) => {
    if(invoice.length > 0) {
      setInvoiceInfo(invoice);
    } else {
      setInvoiceInfo([])
    }
  }

  const invoiceAlreadyExists = () => {
    snackbar.enqueueSnackbar(
      `La factura con el número ${item.invoice_number} ya ha sido introducida anteriormente`,
      { variant: "error" }
    );
  };

  const checkInvoiceExistence = async (invoiceNumber: string) => {
    const response = await invoiceGateway.findInvoicesByQuery({
      invoice_number: invoiceNumber,
    });
    const updatedItem = { ...item, ...{ invoice_number: "" } };
    if (response.length !== 0) {
      invoiceAlreadyExists();
      setItem(updatedItem);
    } else {
      const response = await mongoGateway.findOneItem({
        invoiceNumber: invoiceNumber,
      });
      if (!!response.invoiceNumber) {
        invoiceAlreadyExists();
        setItem(updatedItem);
      } else if(item.supply_reference === invoiceNumber){
        setItem(updatedItem);
        snackbar.enqueueSnackbar(
          `La factura con el número ${item.invoice_number} no puede tener el mismo valor que la referencia`,
          { variant: "error" }
        );
      }
    }
    return;
  };

  const checkCupsCancelled = async (cups: string) => {

    if(cups.length > 0 ){
      const response = await supplyConnectionBotGateway.isCupsCancelled(cups);
      if (!!!response) {
        snackbar.enqueueSnackbar(
          `El suministro con cups/referencia ${item.supply_reference} está cancelado`,
          { variant: "error" }
        );
        const updatedItem = { ...item, ...{ supply_reference: "" } };
        setItem(updatedItem);
        setSupplyInfo(["Vacío"]);
      } else {
        //get supply info
        const responseSupplyInfo = await supplyConnectionBotGateway.getSupplyInfo(cups);
  
        if (typeof responseSupplyInfo !== 'undefined' && responseSupplyInfo !== null)
        {
  
          let supplyToSave = '';
  
          const clientType = clientTypes.find(itm => itm.id === responseSupplyInfo.client_info.type_id);
  
          supplyToSave += `Tipo de Cliente: ${clientType?.clientTypeName},`;
          supplyToSave += `Nombre Cliente: ${responseSupplyInfo.client_info.fullname ?? 'No definido'},`;
          supplyToSave += `Tipo suministro: ${responseSupplyInfo.supply_Subtype},`;
          supplyToSave += `Enviar Email sin validar: ${responseSupplyInfo.sendBeforeValidating ? 'Sí' : 'No'},`;
          supplyToSave += `Enviar Email OP: ${responseSupplyInfo.sendEmail ? 'Sí' : 'No'},`;
          supplyToSave += `Email OP: ${responseSupplyInfo.client_info?.email ?? 'No definido'},`;
          supplyToSave += `Enviar Email Cliente: ${responseSupplyInfo.sendClientEmail ? 'Sí' : 'No'},`;
          supplyToSave += `Emails Cliente: ${responseSupplyInfo.emailNotification ?? 'No definido'},`;
          supplyToSave += `Carpeta configurada: ${responseSupplyInfo.invoicesFolder ?? 'No definido'},`;
          
          const supplyToSaveArr = supplyToSave.split(',').filter(itm => itm.length > 0);
  
          setSupplyInfo(supplyToSaveArr);
        } else {
          setSupplyInfo(["Vacío"]);
          snackbar.enqueueSnackbar(
            `El suministro con cups/referencia ${item.supply_reference} no se ha podido encontrar...`,
            { variant: "warning" }
          );
        }
      }

    } else {
      setSupplyInfo(["Vacío"]);
    }
    return;
  };

  const handleClick = async () => {
    if (
      !!!item.supply_type_id ||
      !!!item.invoice_type_id ||
      !!!item.invoice_number ||
      !!!item.invoice_amount ||
      !!!item.supply_reference ||
      !!!item.issue_date ||
      !!!item.invoice_pdf ||
      !!!item.provider ||
      (isPeriodical && (!!!item.start_period || !!!item.end_period))
    ) {
      snackbar.enqueueSnackbar(`Todos los campos son obligatorios`, {
        variant: "error",
      });
      return;
    }
    try {

      setWaitResponse(true)

      const result = await invoiceGateway.saveManualInvoice(
        item,
        pdfUriData,
        !!!user ? "" : user
      );

      if (
        result &&
        JSON.stringify(result).toLowerCase().includes("processinstancekey")
      ) {
        snackbar.enqueueSnackbar(
          `Los datos introducidos han empezado a procesarse.`,
          {
            variant: "success",
          }
        );
        setItem(initManualInvoice);
        
        setWaitResponse(false);
        setOpenDialog(false);
        setSupplyInfo(["Vacío"])

      } else if (
        result &&
        result.data?.code === 'error'
      ) {
        const msg = result.data?.message;

        if (typeof msg === 'string')
        {
          snackbar.enqueueSnackbar(
            `Error: ` + msg,
            {
              variant: "error",
            }
          );

          setWaitResponse(false)

          setOpenDialog(false);
        } else {
          snackbar.enqueueSnackbar(
            `Error no controlado: ` + JSON.stringify(result.data),
            {
              variant: "error",
            }
          );
        }
        // throw new Error(
        //   "La función no tiene los valores esperados: " + JSON.stringify(result)
        // );
      }
    } catch (e) {
      console.log("add-manual-invoice Error: ", e ? JSON.stringify(e) : e);

      snackbar.enqueueSnackbar(
        `Ha ocurrido un error al lanzar el evento, error: ` + e ? JSON.stringify(e) : String(e),
        {
          variant: "error",
        }
      );

      setWaitResponse(false)

      setOpenDialog(false);
      
    }
  };

  return (
    <>
      <ManualInvoiceForm
        item={item}
        supplyinfo={supplyInfo}
        handleInvoiceHashResult = {handleInvoiceHashResult}
        invoiceInfo={invoiceInfo}
        onChange={handleChange}
        supplyTypes={supplyTypes}
        invoiceTypes={invoiceTypes}
        handlePdfUriData={handlePdfUriData}
        pdfUriData={pdfUriData}
        isPeriodical={isPeriodical}
        onChangeCheckbox={handleIsPeriodical}
        providers={providers}
        handleInvoiceExists={checkInvoiceExistence}
        handleCupsCancelled={checkCupsCancelled}
        handleValidateDate={handleValidateDate}
        handleValidateAmount={handleValidateAmount}
      />
      <Fab
        color="primary"
        aria-label="save"
        className={classes.button}
        // onClick={handleClick} // Manda directamente al hacer click // trasladar a dialog!
        onClick={handleClickOpen}
        style={{ position: "fixed" }}
        disabled={invoiceInfo.length > 0}
      >
        <SaveIcon />
        
      </Fab>

      <Dialog 
        onClose={handleClose} 
        aria-labelledby="customized-dialog-title" 
        open={openDialog}
        >
          <DialogTitle  id="alert-dialog-title">
             <b> GUARDAR FACTURA</b>
          </DialogTitle>
         
          <DialogContent >

            <DialogContentText className={classes.textDialog}>
              Estas seguro de enviar los valores introducidos en el formulario?
            </DialogContentText>


            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography  className={classes.textDialogInfo} variant="h6" gutterBottom display="block" >
                  Suministro:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                  <Typography className={classes.textDialog} variant="body1" gutterBottom display="inline" >
                    {item.provider}
                  </Typography>
              </Grid>
            </Grid>
            
            
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography className={classes.textDialogInfo} variant="h6" gutterBottom display="block" >
                  Cups/referencia:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.textDialog} variant="body1" gutterBottom display="inline" >
                    {item.supply_reference}
                </Typography>
              </Grid>
            </Grid>
            
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography className={classes.textDialogInfo} variant="h6" gutterBottom display="block" >
                Importe total :
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.textDialog} variant="body1" gutterBottom display="inline" >
                {`${item.invoice_amount} €`}
                </Typography>
              </Grid>
            </Grid>
            
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography className={classes.textDialogInfo} variant="h6" gutterBottom display="block" >
                Num Factura:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.textDialog} variant="body1" gutterBottom display="inline" >
                  {item.invoice_number}
                </Typography>
              </Grid>
            </Grid>
        
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography className={classes.textDialogInfo} variant="h6" gutterBottom display="block" >
                Fecha de emisión:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.textDialog} variant="body1" gutterBottom display="inline" >
                  {item.issue_date?.toLocaleString().slice(0, 10)}
                </Typography>
              </Grid>
            </Grid>

              {(isPeriodical) ? (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography className={classes.textDialogInfo} variant="h6" gutterBottom display="block" >
                      Fecha de inicio:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography className={classes.textDialog} variant="body1" gutterBottom display="inline" >
                      {item.start_period?.toLocaleString().slice(0, 10)}
                      </Typography>
                    </Grid>
                 </Grid>
           
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography className={classes.textDialogInfo} variant="h6" gutterBottom display="block" >
                      Fecha de fin:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography className={classes.textDialog} variant="body1" gutterBottom display="inline" >
                      {item.end_period?.toLocaleString().slice(0, 10)}
                      </Typography>
                    </Grid>
                  </Grid>
                </>

              ):(
                <></>
              )}

          </DialogContent>
          <DialogActions>
            {/* waitResponse */}
            <Button disabled={waitResponse} variant="contained" autoFocus onClick={handleClick} color="primary">
              Confirmar
            </Button>
            <Button variant="contained" autoFocus onClick={handleClose} color="secondary">
              Cancelar
            </Button>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default AddManualInvoice;
